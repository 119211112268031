import React from "react"

export const dropDownData = {
  android: (
    <a
      href={"https://play.google.com/store/apps/details?id=com.onblix.app"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/Group-24.svg"}
          alt="android"
          style={{ width: "20px", marginRight: "13px" }}
        />
        {"Download for Android"}
      </button>
    </a>
  ),
  linux_deb: (
    <a
      href={"https://download.bluemail.me/BlueMail/deb/BlueMail.deb"}
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/Openlogo-debianV2.svg"}
          alt="linux"
          style={{ width: "16px", marginRight: "16px" }}
        />
        {"Download for Linux DEB"}
      </button>
    </a>
  ),
  ios_main: (
    <a
      href={"https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button" style={{ justifyContent: "center" }}>
        <img
          src={"/images/home-page/Group1.svg"}
          alt="ios"
          style={{ width: "20px", marginRight: "12px" }}
        />
        {"Download for iOS"}
      </button>
    </a>
  ),
  ios: (
    <a
      href={"https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button" style={{ justifyContent: "center" }}>
        <img
          src={"/images/home-page/Group.svg"}
          alt="ios"
          style={{ width: "20px", marginRight: "12px" }}
        />
        {"Download for iOS"}
      </button>
    </a>
  ),
  windows_9_10: (
    <a
      href={"https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/icon-1-blue.svg"}
          alt="win"
          style={{ width: "20px", marginRight: "12px", height: "23px" }}
        />
        {"Download for Windows"}
      </button>
    </a>
  ),
  windows_9_10_main: (
    <a
      href={"https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/icon-1-white.svg"}
          alt="win"
          style={{ width: "20px", marginRight: "13px" }}
        />
        {"Download for Windows"}
      </button>
    </a>
  ),
  windows_7_8: (
    <a
      href={"https://download.bluemail.me/BlueMail-Desktop-Installer.exe"}
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/Windows7.svg"}
          alt="win"
          style={{ width: "22.5px", marginRight: "9px" }}
        />
        {"Download for Windows 7, 8"}
      </button>
    </a>
  ),
  mac_main: (
    <a
      href={"https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button" style={{ justifyContent: "center" }}>
        <img
          src={"/images/home-page/Group1.svg"}
          alt="mac"
          style={{ width: "20px", marginRight: "18px", marginBottom: "3px" }}
        />
        {"Download for macOS"}
      </button>
    </a>
  ),
  mac: (
    <a
      href={"https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button" style={{ justifyContent: "center" }}>
        <img
          src={"/images/home-page/Group.svg"}
          alt="mac"
          style={{ width: "20px", marginRight: "18px", marginBottom: "3px" }}
        />
        {"Download for macOS"}
      </button>
    </a>
  ),
  amazon: (
    <a
      href={"https://www.amazon.com/BlueMail-Inc-Email-Calendar/dp/B01DUUITZO"}
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/Style_2_copy_3.svg"}
          alt="amazon"
          style={{ width: "20px", marginRight: "12px" }}
        />
        {"Download for Amazon"}
      </button>
    </a>
  ),
  linux_prm: (
    <a
      href={"https://download.bluemail.me/BlueMail/rpm/BlueMail.rpm"}
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/RedHat.svg"}
          alt="linux"
          style={{ width: "20px", marginRight: "12px" }}
        />
        {"Download for Linux RPM"}
      </button>
    </a>
  ),
  linux_snap: (
    <a
      href={"https://snapcraft.io/blix"}
      target="_blank"
      rel="noopener noreferrer"
      style={{ maxWidth: "100%", textDecoration: "none" }}
    >
      <button className="platform-button">
        <img
          src={"/images/home-page/19532717.png"}
          alt="linux"
          style={{ width: "24px", marginRight: "8px" }}
        />
        {"Download for Linux"}
      </button>
    </a>
  ),
}

export const platformString = {
  ios: (
    <div>
      <p className="platform-string">
        Also available on{" "}
        <a
          href="https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Windows
        </a>
        ,{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mac
        </a>
        ,{" "}
        <a
          href="https://snapcraft.io/blix"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linux
        </a>{" "}
        and{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.onblix.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>
      </p>
    </div>
  ),
  windows_9_10: (
    <div>
      <p className="platform-string">
        Also available on{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mac
        </a>
        ,{" "}
        <a
          href="https://snapcraft.io/blix"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linux
        </a>
        ,{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.onblix.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>{" "}
        and{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </a>
      </p>
    </div>
  ),
  android: (
    <div>
      <p className="platform-string">
        Also available on{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </a>
        ,{" "}
        <a
          href="https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Windows
        </a>
        ,{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mac
        </a>{" "}
        and{" "}
        <a
          href="https://snapcraft.io/blix"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linux
        </a>
      </p>
    </div>
  ),
  mac: (
    <div>
      <p className="platform-string">
        Also available on{" "}
        <a
          href="https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Windows
        </a>
        ,{" "}
        <a
          href="https://snapcraft.io/blix"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linux
        </a>
        ,{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.onblix.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>{" "}
        and{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </a>
      </p>
    </div>
  ),
  amazon: (
    <div>
      <p className="platform-string">
        Also available on{" "}
        <a
          href="https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Windows
        </a>
        ,{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mac
        </a>
        ,{" "}
        <a
          href="https://snapcraft.io/blix"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linux
        </a>
        ,{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.onblix.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>{" "}
        and{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </a>
      </p>
    </div>
  ),
  linux_snap: (
    <div>
      <p className="platform-string">
        Also available on{" "}
        <a
          href="https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Windows
        </a>
        ,{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mac
        </a>
        ,{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.onblix.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>{" "}
        and{" "}
        <a
          href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </a>
      </p>
    </div>
  ),
}