import React, { Component } from "react"
import { Typography, Grid } from "@material-ui/core"
import DropDownButton from "../drop-down-button"
import { isMobile } from "react-device-detect";

class content extends Component {

  qr = () => {
    let { imagesPrefix } = this.props
    return (
      <div
        className="qr-wrapper"
      >
        <img src={`${imagesPrefix}/images/qrc_1.png`} alt="QR Blix" style={{ width: "100%" }} />
        <p
          className="qr-text"
        >
          Scan with your phone to download
        </p>
      </div>
    )
  }

  render() {
    let { imagesPrefix } = this.props;
    return (
      <div className="content-wrapper">
        <Typography
          align="center"
          variant="h3"
          color="textPrimary"
          className="content-header"
        >
          Download Blix Today
        </Typography>
        <Typography
          align="center"
          gutterBottom
          component="p"
          color="textSecondary"
          className="content-subtext"
        >
          Blix is a secure, fast, easy to use, cross platform email app which is
          capable of handling an unlimited number of email accounts from any
          email provider. With unified folders, people-centric option, sharing
          emails, clusters, and a truly remarkable user interface and
          experience, Blix will make you love email again.
        </Typography>
        <DropDownButton imagesPrefix={imagesPrefix} />
        <div>
          <Grid container justify="center" alignItems="center">
            <Grid item md={2}>

            </Grid>
            <Grid item md={8}>
            <img
            src={`${imagesPrefix}/images/device-family-1.webp`}
            className="content-image"
            alt="lix-desktop-mobile-family"
          />
            </Grid>
            <Grid item md={2}>
            {!isMobile && this.qr()}
            </Grid>
          </Grid>
          
          
        </div>
      </div>
    )
  }
}

export default content
