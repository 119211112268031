import React, { Component } from "react"
import { dropDownData, platformString } from "../utils/drop-down-data"

class DropDownButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInvalidEmailError: false,
      email: "",
      open: false,
      output: "",
      platformsString: "",
      platform: "",
      up_down: false,
      clickedOutside: false,
    }
  }

  componentDidMount() {
    let isClient = typeof window !== "undefined"

    if (isClient) {
      let userAgent = window.navigator.userAgent
      if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i)) {
        this.setState({ output: dropDownData.ios_main })
        this.setState({ platformsString: platformString.ios })
        this.setState({ platform: "ios" })
      } else if (userAgent.match(/Windows/g)) {
        this.setState({ output: dropDownData.windows_9_10_main })
        this.setState({ platformsString: platformString.windows_9_10 })
        this.setState({ platform: "win" })
      } else if (userAgent.match(/Android/i)) {
        this.setState({ output: dropDownData.android })
        this.setState({ platformsString: platformString.android })
        this.setState({ platform: "android" })
      } else if (userAgent.match(/Mac/i)) {
        this.setState({ output: dropDownData.mac_main })
        this.setState({ platformsString: platformString.mac })
        this.setState({ platform: "mac" })
      }
      // else if(userAgent.match(/Silk/i)){
      //     this.setState({output: dropDownData.amazon})
      //     this.setState({platformsString : platformString.amazon});
      //     this.setState({platform: 'amazon'})
      // }
      // else if(userAgent.match(/Linux/i)){
      //     this.setState({output: dropDownData.linux_snap})
      //     this.setState({platformsString : platformString.linux_snap});
      //     this.setState({platform: 'linux'})
      // }
      else {
        this.setState({ output: dropDownData.android })
        this.setState({ platformsString: platformString.android })
        this.setState({ platform: "android" })
      }
    }


    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (this.myRef && !this.myRef.current.contains(e.target)) {
      this.setState(state => {
        return {
          open: false,
        }
      })
      this.setState({ up_down: false })
    }
  }

  handleClickInside = () => {
    this.setState(state => {
      return {
        open: !state.open,
      }
    })
    this.setState({ up_down: !this.state.up_down })
  }
  myRef = React.createRef()

  render() {
    let { up_down, output, platform, platformsString } = this.state;
    let { imagesPrefix } = this.props;
    return (
        <div className="platform-output-container">
            <div
              className="container"
              style={{ position: "relative", maxWidth: "300px" }}
              ref={this.myRef}
            >
              {output && (
                <div
                  style={{
                    display: "inline-flex",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    borderRadius: "5px",
                  }}
                >
                  {output}
                  <button
                    className={
                      up_down
                        ? "arrow-drop-down-btn_open"
                        : "arrow-drop-down-btn_close"
                    }
                    onClick={this.handleClickInside}
                  >
                    {up_down ? (
                      <img
                        style={{ height: "13px", marginBottom: "5px" }}
                        src={`${imagesPrefix}/images/home-page/up-chevron.svg`}
                        alt="down-up"
                      />
                    ) : (
                      <img
                        style={{ height: "13px", marginBottom: "0px" }}
                        src={`${imagesPrefix}/images/home-page/down-chevron.svg`}
                        alt="down-up"
                      />
                    )}
                  </button>
                </div>
              )}
              <div style={{ height: "0" }}>
                {this.state.open && (
                  <div className="dropdownh">
                    <ul className="ul-op-drop">
                      {platform !== "mac" && (
                        <div>
                          <li className="mac-data">{dropDownData.mac}</li>
                          <hr />
                        </div>
                      )}
                      {platform !== "ios" && (
                        <div>
                          <li>{dropDownData.ios}</li>
                          <hr />
                        </div>
                      )}
                      {platform !== "android" && (
                        <div>
                          <li>{dropDownData.android}</li>
                          <hr />
                        </div>
                      )}
                      {/* {platform !== 'amazon' && <div><li>{dropDownData.amazon}</li><hr/></div>} */}
                      {platform !== "win" && (
                        <div>
                          <li>{dropDownData.windows_9_10}</li>
                          <hr />
                        </div>
                      )}
                      {/* {<div><li>{dropDownData.windows_7_8}</li><hr/></div>} */}
                      {/* {<div><li>{dropDownData.linux_deb}</li><hr/></div>} */}
                      {/* {<div><li>{dropDownData.linux_prm}</li><hr/></div>} */}
                      {platform !== "linux" && (
                        <li>{dropDownData.linux_snap}</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {platformsString}
            </div>
          </div>
    )
  }
}

export default DropDownButton