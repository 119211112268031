import React from "react"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import SEO from "../components/seo"
import Layout from '../components/layout';
import Content from "../components/dl-page/index";
import { graphql, StaticQuery } from 'gatsby';

const Dl = ({ location }) => {
  //   componentDidMount() {
  //     // if (typeof window !== `undefined`){
  //     //     window.location.replace(`/download`)
  //     // }
  //     // trackCustomEvent({
  //     //     // string - required - The object that was interacted with (e.g.video)
  //     //     category: "Blix_site_redirect_from_dl_to_download_page_from_signature_link",
  //     //     // string - required - Type of interaction (e.g. 'play')
  //     //     action: "Redirect",
  //     //     // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  //     //     label: "Gatsby Plugin Example Campaign",
  //     //     // number - optional - Numeric value associated with the event. (e.g. A product ID)
  //     //     // value: 43
  //     // })
  //   }

  return (

        <StaticQuery
        query={graphql`
        query DlPageQuery {
          site {
            siteMetadata {
              title
              env
              imagesPrefix
            }
          }
        }
        `}
        render={data => (
          <Layout location={location}>
            <div className="dl-wrapper">
              <SEO
                title="Download Blix"
                description="blix: boost your work, switch between email and chat without leaving Blix, Receive emails directly inside your chat conversation"
              />
              <Content imagesPrefix={data.site.siteMetadata.imagesPrefix} />
            </div>
          </Layout>
        )}
        />
    
  )
}

export default Dl;
